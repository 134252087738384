@import '/src/styles/mixins';

.container {
  display: flex;
  border-radius: 20px;
  margin: 10px;
  margin-top: 0px;
  flex: 3;
  position: relative;
}

.infoModal {
  position: absolute;
  height: 97%;
  width: 200px;
  margin: 10px;
}

@include sm-max {
  .container {
    margin-top: 10px;
  }
}
