@import '/src/styles/colours';

.form {
  display: flex;
  flex-direction: column;

  .formInput {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
  }
  .left {
    width: 50%;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 50%;
    justify-content: flex-start;
    margin-right: 10px;
  }

  .imgUpload {
    height: 100px;
    width: 100%;
    border: 1px solid $brand-dark;
  }

  .button {
    background-color: $brand-light;
    color: whitesmoke;
    display: flex;
    justify-content: center;

    button {
      background-color: $brand-light;
      /**border-radius: 10px;**/
      width: 100%;
      /**border: 0px;**/
      &:hover {
        // box-shadow: inset 0px 0px 10px 1px white;
        background-color: $brand-light !important;
        color: $brand-dark !important;
        border: 0px !important;
      }
      &:disabled {
        background-color: lightgray;
        width: 100px;
        border: 0px;
        border-radius: 0px;
        &:hover {
          background-color: lightgray !important;
          color: white !important;
        }
      }
    }
  }
}

.img {
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
  }
}
