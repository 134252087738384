@import '/src/styles/colours';

.h1Wrapper {
  display: flex;
  width: 100%;
  height: 60px;
  color: whitesmoke;
  background-color: $brand-dark;
  padding: 10px;
  justify-content: space-between;
  align-items: center;

  h1 {
    margin-top: 15px;
  }
}

.main {
  padding: 10px;
}
