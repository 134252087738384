.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(235, 235, 245);
  padding: 2rem;
  height: 100%;
}

.title {
  font-size: 2rem;
  margin-bottom: 20px;
}

.controlsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.signalContainer {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.statusButton,
.signalButton {
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

span {
  padding: 0px 5px;
}

.statusButton:hover {
  background-color: blue;
  transition: background-color 0.3s ease;
}

.statusButton:disabled {
  background-color: grey;
}

.signalButton:hover {
  background-color: green;
  transition: background-color 0.3s ease;
}

.signalButton:disabled {
  background-color: grey;
}

.parkingList {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.parkingItem {
  background-color: white;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 500px;
}

.parkingItem p {
  margin: 0.5rem 0;
}

img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
}
