.myProfile {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 245);
  padding: 2rem;
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-wrapper h3 {
  margin-bottom: 1rem;
}

.profile-wrapper img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.profile-edit-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profile-edit-form label {
  margin-bottom: 0.5em;
  font-weight: bold;
}

.profile-edit-form input {
  padding: 0.5em;
  margin-bottom: 1em;
  border-radius: 0.25em;
  border: 1px solid #ccc;
  width: 100%;
}

.profile-edit-form button {
  padding: 0.75em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profile-edit-form button:hover {
  background-color: #0056b3;
}

h1 {
  margin-top: 2rem;
}

.cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-item {
  display: flex;
  justify-content: space-between; /* Space between text and button */
  align-items: center; /* Center align text and button */
  flex-wrap: wrap; /* Wrap text to next line if needed */
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  padding: 1em;
  margin: 0.5em 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease;
}

.card-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-item button {
  padding: 0.5em 1em;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 0.3em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.card-item button:hover {
  background-color: #c82333;
}

.card-item span {
  font-size: 1em;
}

.card-icon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.add-card-button {
  padding: 0.5em 1em;
  margin-bottom: 1em;
  align-self: center;
  text-align: center;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 0.3em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-wrapper {
  padding: 20px;
  margin: 20px;
  color: #007bff;
  background-color: #f2f2f2;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.contact-wrapper div {
  margin: 10px 0;
}

.contact-wrapper a {
  color: #007bff;
  text-decoration: none;
}

.contact-wrapper a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.about-us {
  margin: 20px;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.about-us div {
  margin-bottom: 10px;
}

.about-us a {
  color: #007bff;
  text-decoration: none;
}
.about-us a:hover {
  color: #0056b3;
  text-decoration: underline;
}
