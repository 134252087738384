@import '/src/styles/colours';
@import '/src/styles/mixins';

.mainInfo {
  position: relative;
  padding: 10px;
  background-color: $brand-dark;
  /* border-radius: 10px 10px 0px 0px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 10px;
  color: white;
  div:nth-child(2) {
    margin-left: -2px;
  }
}

.col {
  display: flex;
  font-size: 14px;
}

.name {
  @include text-bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.icons {
  width: 25px;
  height: 25px;
  margin-right: 10px;

  svg {
    color: white !important;
    font-size: 20px;
  }
}

.review {
  display: flex;
  flex-direction: column;
  flex: 1;

  .rating {
    font-size: 48px;
    color: whitesmoke;
  }
  img {
    width: 18px;
    height: 18px;
  }

  .starContainer {
    height: 18px;
  }
}

.button {
  color: $brand-light;
  display: flex;
  justify-content: flex-end;
  button {
    background-color: transparent !important;
    border: 0px !important;
    font-size: 16px;
    color: $brand-light;

    &:hover {
      background-color: $brand-light !important;
      color: $brand-dark !important;
      border-radius: 10px;
    }
  }
}

@include sm-max {
  .mainInfo {
    margin-top: 0px;
  }
}
