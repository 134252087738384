@import '/src/styles/colours';
@import '/src/styles/mixins';

.form {
  display: flex;
  flex-direction: column;
  margin: 3px;
  gap: 1rem;
  color: white;
  padding: 15px;
  border: 1px solid $brand-light;
  border-radius: 15px;
  box-shadow: 0px 0px 5px 3px $brand-light;

  label {
    color: white;
  }

  button {
    width: 100%;
    background-color: $brand-light;
    border-radius: 10px;
    border: 0px;
    &:hover {
      // box-shadow: inset 0px 0px 10px 1px white;
      background-color: $brand-light !important;
      color: $brand-dark !important;
    }
  }

  .time {
    margin-left: 4%;
  }
}

@include sm-max {
  .form {
    margin-left: 0px;
    margin: 10px;
    .mobileForm {
      display: flex;
      flex-direction: column;
    }
    .destinationAndDuration {
      flex: 2;
  
    }
    .dateAndTime {
      flex-direction: column;
      flex: 1;
    }
    .time {
      margin-left: 0;
    }
  }
}
