.day{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span{
        margin: 5px;

        &:first-child{
            margin-left: 0px;
        }
    }

    div{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}