@import '/src/styles/colours';

.wrapper {
  width: 100%;

  .today {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      margin: 5px;

      &:first-child {
        margin-left: 0px;
      }
    }

    &:hover {
      color: $brand-light;
      border: 1px solid $brand-light;
      border-radius: 5px;
    }

    .dropDownBtn {
      width: 20px;
      height: 20px;
    }
  }

  .dayInAWeek {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    z-index: 100;
    background-color: $brand-dark;
    border: 2px solid $brand-light;
    border-radius: 10px;
    padding: 10px;
  }
}
