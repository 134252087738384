@import '/src/styles/colours';

.button {
  margin: 20px;
  height: 10%;

  button {
    display: flex;
    background-color: transparent;
    color: $brand-dark;
    border: 1px solid $brand-dark;
    border-radius: 10px;

    .plus {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .backBtn{
   display: flex !important;
  
  }
}
