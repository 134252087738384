.profileItem {
  display: flex;
  justify-content: flex-start; /* Use 'flex-start' instead of 'start' for clarity */
  width: 75%;
  height: auto; /* This is good for flexible height */
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.54);
  background-color: rgb(250, 250, 255);
  color: gray;
  border-radius: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  padding: 2em;
  flex-direction: column; /* Stacked vertically */
}

.profileItemHeader {
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.profileItemHeader:hover {
  color: black;
}

.profileItemBody {
  max-height: 0; /* Initially collapsed */
  overflow: hidden;
  transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
  opacity: 0; /* Initially hidden */
}

.profileItemBody.open {
  max-height: 500px; /* Arbitrary large value for open state */
  opacity: 1; /* Fully visible when open */
}

.profileItemBody p {
  margin: 0;
}
