@import '/src/styles/colours';

.dayPickerWrapper {
  margin-top: 10px;
  label {
    color: $brand-dark;
  }

}
.day{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
