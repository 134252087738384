@import '/src/styles/colours';
@import '/src/styles/mixins';

.wrapper {
  height: 115px;
  width: 100%;
  padding: 5px;
  margin: 0 auto;
  scroll-snap-align: start;
}

.item {
  width: 100%;
  height: 100%;
  background-color: $brand-dark;
  color: white;
  box-shadow: 0px 0px 4px 1px whitesmoke;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  &:hover {
    outline: 4px solid $brand-light;
  }
  &:active {
    border: 1px solid whitesmoke;
    box-shadow: inset 0px 0px 18px 0px whitesmoke;
    outline: none;
  }

  .img {
    display: flex;
    flex: 1;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .right {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
  }

  .name {
    @include text-bold;
    font-size: 16px;
  }

  .address {
    @include text-small;
    font-style: italic;
    margin: 6px 0px;
  }

  .price {
    font-size: medium;
    font-style: normal;
  }
}
