@import '/src/styles/colours';
@import '/src/styles/mixins';

.wrapper {
  flex: 0 0 auto;
  margin: 20px;
  width: 450px; // Adjust the width of each item as per your requirement

  border: 1px solid $brand-light;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 3px $brand-dark;
  padding: 10px;
  margin-right: 10px;
  background-color: #f5f5f5;
  padding: 10px;

  .img {
    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  .down {
    & > div {
      justify-content: space-between;
      padding: 10px;
      overflow: hidden;
    }

    .infoWrapper {
      display: flex;
      background-color: $brand-dark;
      color: whitesmoke;
      gap: 1rem;
      margin-top: 10px;
      flex-direction: column;
      padding: 10px;
      border-radius: 10px;
      & > div {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .workingDay {
    display: flex;
    justify-content: space-between;
  }

  .button {
    display: flex;

    .editButton {
      display: flex;
      width: 100%;
      height: 50px;
      margin-right: 10px;
      justify-content: center;
      border-radius: 10px;
      align-items: center;
      background-color: transparent;
      color: $brand-dark;
      border: 2px solid $brand-dark;

      &:hover {
        background-color: $brand-light;
        color: $brand-dark;
      }
    }

    .deleteBtn {
      display: flex;
      border: 1px solid red;
      border-radius: 10px;
      width: 50px;
      height: 50px;
      align-items: center;
      justify-content: center;
      background-color: whitesmoke;
      padding: 10px;
      margin: 1px;
      &:hover {
        background-color: rgba($color: $warningRed, $alpha: 0.3);
      }
    }
  }
}

@include lg-max {
  .wrapper {
    width: 53% !important;
    height: 70%;
    margin-right: 10px;
  }
}
@include md-max {
  .wrapper {
    width: 95% !important;
    height: 91%;
  }
}

@include xs-max {
  .wrapper {
    width: 95% !important;
    height: 91%;
  }
}
