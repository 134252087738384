@import '../../styles/colours.scss';
@import '../../styles/typography.scss';

.ant-btn {
  border-color: $primary-light;
  background: $primary-light;
  color: white;
  border-radius: 4px;

  span {
    @include text-strong();
  }

  &:hover {
    border-color: $primary-medium;
    background: $primary-medium;
    color: white;
  }

  &:active {
    border-color: $primary-dark;
    background: $primary-dark;
    color: white;
  }

  &:disabled {
    background-color: $neutral-light;
    color: white;

    &:hover {
      background-color: $neutral-light;
    }
  }
}

.ant-btn-dangerous {
  border-color: $alert-light;
  background: $alert-light;

  &:hover {
    border-color: $alert-medium;
    background: $alert-medium;
  }

  &:active,
  &:focus {
    border-color: $alert-dark;
    background: $alert-dark;
  }
}

.ant-btn-primary {
  border-color: $confirm-light;
  background: $confirm-light;

  &:hover {
    border-color: $confirm-medium;
    background: $confirm-medium;
  }

  &:active,
  &:focus {
    border-color: $confirm-dark;
    background: $confirm-dark;
  }
}

.ant-btn-ghost {
  border-color: white;
  background: transparent;

  &:hover {
    border-color: white;
    background: white;
    color: $brand-dark;
  }

  &:active {
    border-color: white;
    background: white;
    color: $brand-dark;
  }
}
