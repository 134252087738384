@import 'colours';
@import 'typography';

.ant-input-prefix {
  color: rgb(64, 126, 197);
}

.ant-form-item {
  margin: 0;
}

.ant-picker {
  width: 100%;
  color: $text-light;
}

.ant-btn {
  border-radius: 2px;
}

// Ant Layout Overrides

.code-box-demo {
  text-align: center;
}

.ant-layout-header {
  color: black;
  background: white;
  padding: 0;
}

.ant-layout-footer {
  line-height: 1.5;
}

.ant-layout-sider {
  color: #fff;
  line-height: 30px;
  // background: #3ba0e9;
}

// Modal

.ant-modal-header {
  padding: 16px 24px;
}

.ant-upload-text-icon {
  display: none;
}

.ant-upload-span {
  padding-left: 20px;
}

// table
.ant-table-cell {
  font-size: 12px !important;
}

.ant-table {
  border-bottom: 1px solid lightgray;
}

// Color overrides, not to eject an app we use simple overrides

.ant-select,
.ant-input {
  color: $text-light;
  width: 100%;
}

.ant-btn {
  color: white;
  span {
    @include text-strong();
  }

  &:hover {
    border-color: $primary-medium;
    background: $primary-medium;
    color: white;
  }

  &:active {
    border-color: $primary-dark;
    background: $primary-dark;
    color: white;
  }

  &:disabled {
    background-color: $neutral-light;
    color: white;

    &:hover {
      background-color: $neutral-light;
    }
  }
}

.ant-btn-dangerous {
  border-color: $alert-light;
  background: $alert-light;
  color: $neutral-pail;

  &:hover {
    border-color: $alert-medium;
    background: $alert-medium;
  }

  &:active,
  &:focus {
    border-color: $alert-dark;
    background: $alert-dark;
  }
}

.ant-btn-primary {
  border-color: $brand-light;
  background-color: $brand-dark;

  &:hover {
    border-color: $confirm-medium;
    background: $confirm-medium;
  }

  &:active,
  &:focus {
    border-color: $brand-dark;
    background: $brand-dark;
  }
}

.ant-btn-ghost {
  border-color: white;
  background: transparent;

  &:hover {
    border-color: white;
    background: white;
    color: $brand-dark;
  }

  &:active {
    border-color: white;
    background: white;
    color: $brand-dark;
  }
}

.ant-form-item {
  color: white;
}

.ant-form-item-label {
  color: white;
}

.ant-btn-default.ant-btn-dangerous {
  color: white;
  border-color: white;
}

.ant-btn-primary:not(:disabled):hover {
  color: $white;
  background-color: $brand-light;
}

.ant-btn-default:not(:disabled):hover {
  color: $brand-dark;
  // background-color: transparent;
  border: 1px solid $brand-dark;
}

// parking form override classes
#parking-search {
  label {
    color: whitesmoke;
    margin-bottom: 0;
    margin-left: 5px;
  }
  .date-picker {
    flex: 2;
    display: flex;
  }

  .ant-col {
    padding: 0;
    margin: 0;
  }
}

#parking-place {
  width: 700px;
}
#new-parking-place {
  .ant-modal .ant-modal-content {
    padding: 0px;
  }
}

.ant-modal .ant-modal-content {
  padding: 0px;
}

#new-parking-form {
  .ant-modal {
    width: 60%;
  }

  .ant-form-item-label {
    padding: 0px;
  }

  .ant-picker {
    
    color: $brand-dark;
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid $brand-dark;
    // margin-left: 30px;
    svg {
      color: $brand-dark;
    }
  }
  .ant-picker-input > input::placeholder {
    color: $brand-dark;
  }
}

#time-per-day {
  .ant-form-item-label {
    flex: 1;
    margin-right: 8px;
  }
  .ant-form-item-control {
    flex: 1;
  }
}

#comment-text-area {
  .ant-input:hover {
    border-color: $brand-light;
  }
}

#reservation-form {
  .ant-btn-default:not(:disabled):hover {
    opacity: 0.7;
    color: white;
  }
}


#edit-reservation-modal{
  .ant-modal-header{
    padding: 0px;
  }
}
