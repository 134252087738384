@import '/src/styles/colours';

.wrapperButtons{
    display: flex;
    justify-content: space-between;
}

.deleteBtn{
    width: 50%;
    margin-right: 5px;

    button{

        background-color: $alert-light !important;
        color: white;
        &:hover{
            border: 1px solid $alert-dark !important;
            opacity: 1;
        }
    }
}


.confirmBtn{
    button {
        background-color: $brand-light;
        border: 0px;
  
        &:hover{
            color: $brand-dark !important;
            background-color: $brand-light !important;
            opacity: 1 !important;
        }
      }
}