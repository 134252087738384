@import '/src/styles/colours';

.button {
  border: 0px;
  background-color: transparent;
  color: whitesmoke;
  height: 35px;
  width: 80px;
  &:hover {
    color: $brand-light;
  }
}
