.star {
  font-size: 24px;
  color: white;
  display: inline-block;
}

.filled {
  color: #ffd700;
}

.half {
  position: relative;
}

.half::before {
  content: '★';
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  color: #ffd700;
  clip-path: inset(-50% 0 -50% 0);
}

.star-rating {
  display: flex;
  align-items: center;
}

.average-star {
  margin-top: 2px;
  font-size: 18px;
  color: white;
}
