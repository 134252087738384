@import '/src/styles/mixins';

.main {
  display: flex;
  flex-grow: 1;
  height: 91vh;
}

.left {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding-left: 5px;
  flex: 1;
}

@include sm-max {
  .main {
    flex-direction: column;
  }

  .left {
    width: 100%;
    padding-left: 0px;
  }
}
