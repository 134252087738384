@import '/src/styles/colours';
@import '/src/styles/mixins';

.wrapperInfo {
  width: 250px;
  height: auto;
  z-index: 200;
  position: absolute;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
}

.infoParking {
  height: 100%;
  width: 100%;
  background-color: rgba(245, 245, 245, 0.9);
  border-radius: 10px;
  color: whitesmoke;
  box-shadow: 0px 0px 20px 6px $brand-dark;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  background-color: $brand-dark;
  button {
    margin: 5px;
  }
}

.img {
  display: flex;
  flex: 1;
  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.footer {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  border-radius: 0px 0px 10px 10px;
  background-color: $brand-light;
  color: $brand-dark;
  align-items: center;
  font-size: bold;

  button {
    background-color: $brand-light;
    display: flex;
    justify-content: center;
    align-items: center;

    border: 0px;
    &:hover {
      background-color: transparent !important;
      color: $brand-dark !important;
      border: transparent !important;
    }
  }

  div {
    margin: 0 auto;
  }
}

@include xxs-max {
  .wrapperInfo {
    width: 95%;
    margin-top: 0;
    margin-left: 0;
    margin: 5px;
  }
}

@include sm-max {
  .img {
    display: none;
  }
}
