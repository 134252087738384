@import '../../../../../styles/colours';
.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  //background-color: $brand-dark;
  padding: 10px;
  color: $brand-light;
}

.wrapper {
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
}

.navLinks {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;
}

.logo {
  display: flex;
  height: 40px;
  color: whitesmoke;
  p {
    font-size: 36px;
  }
  span {
    color: $brand-light;
    font-size: 36px;
  }
}

.info {
  color: white;
  cursor: pointer;
}

.info:hover {
  color: $brand-light;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    background-color: transparent;
    border: 1px solid white;

    &:hover {
      color: $brand-light !important;
      border: 1px solid $brand-light !important;
    }
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    width: 100%;
    justify-content: center;
  }
  .navLinks {
    justify-content: center;
  }
}

@media screen and (max-width: 1500px) {
  .wrapper {
    width: 100%;
  }
}
