@import '/src/styles/colours';

.addComment {
  display: flex;
  margin-top: 2%;
  button {
    margin: 0 auto;
    border-radius: 20px;
    background-color: transparent;
    color: $brand-dark;
    border: 1px solid $brand-dark !important;
    height: 30px;
    width: 90%;
    font-size: 14px;

    &:hover{
      background-color: $brand-light !important;

    }
  }
}

.form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  textarea {
    width: 100%;
    border-radius: 4px;
    font-size: 12px;
    color: $brand-dark;
    margin-bottom: 5px;
  }
  button {
    margin: 0 auto;
    border-radius: 20px;
    color: whitesmoke;
    height: 30px;
    width: 50%;
    font-size: 12px;
  }

  .submit{
    background-color: $brand-light !important;
  }
}
