@import '/src/styles/colours';
@import '/src/styles/mixins';


.horizontal {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  & > div{
    display: flex;
    flex-wrap: nowrap;
    padding: 10px;
  }

  &::-webkit-scrollbar {
    height: 5px;
  }
  
  &::-webkit-scrollbar-track {
    margin-left: 10px;/* Adjust the width of the scrollbar */
    background-color: #aaaaaa; /* Color of the scrollbar track */
    border-radius: 4px; /* Border radius of the thumb */
  }
  
  &::-webkit-scrollbar-thumb {
   border-width: 10px;
    background-color: $brand-dark; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Border radius of the thumb */
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background-color: $brand-light; /* Color of the scrollbar thumb on hover */
  }
  
  &::-webkit-scrollbar-thumb:active {
    background-color: $brand-light; /* Color of the scrollbar thumb when active/pressed */
  }
}

.wrapperScroll {
  list-style: none;
  padding: 0;
  margin: 0;
}
