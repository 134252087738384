@import '/src/styles/colours';

.commentList {
  width: 100%;
  color: $brand-dark;
  padding: 10px;
  overflow-y: auto;
  height: 180px;
  padding-bottom: 80px;
}

.listItem {
  border: 1px solid $brand-dark;
  border-radius: 4px;
  box-shadow: 0px 0px 2px 0px $brand-dark;
  padding: 8px;
  margin-bottom: 5px;
  span {
    font-size: 12px;
    font-weight: 700;
  }
  div {
    font-size: medium;
    font-size: 11px;
  }
}
