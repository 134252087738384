@import '/src/styles/typography';
@import '/src/styles/colours';

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9e9e9e;
  @include text-italic();
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #9e9e9e;
  @include text-italic();
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #9e9e9e;
  @include text-italic();
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #9e9e9e;
  @include text-italic();
}

.ant-form-item-has-success {
  .ant-input-prefix {
    color: #9e9e9e;
  }
}

.ant-select-selection-placeholder {
  color: #9e9e9e;
  @include text-italic();
  opacity: 0.7;
}
