@import '/src/styles/colours';

.svg {
    width: 3rem;
    height: 3rem;
  }
  
  .svg path,
  .svg polygon,
  .svg rect {
    fill: $brand-dark;
  }
  
  .svg circle {
    stroke: #4691f6;
    stroke-width: 1;
  }