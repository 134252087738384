.wrapperDateTimeDuration {
  display: flex;
  justify-content: space-between;

  // .dateAndTime{
  //   display: flex;
  //   flex-direction: column;
   
  // }

  .duration{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
     & > div {
      display: flex;
     }
     span {
      margin-left: 5px;
      font-size: 18px;
     }
   
  }

  .ant-form-item {
    display: flex;
    flex-direction: column;
  }
}
