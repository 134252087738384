.reservationsBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgb(235, 235, 245);
  color: black;
  height: auto;
}

.reservationsBox h1 {
  width: 100%;
  text-align: center;
  margin: 1rem;
}
