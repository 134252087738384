@import '/src/styles/colours';

.wrapper {
  display: flex;
  flex-direction: column;
}

.boxwrapper {
  display: flex;
  width: 20%;
  justify-content: center;
}

.parkingPlace {
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding: 10px;
  align-items: center;

  label {
    width: 10%;
  }
}

.listInfo {
  display: flex;
  flex-direction: row;
}

.box {
  display: flex;
  flex-direction: column;
  width: 120px;
  border: 1px solid $brand-dark;
  margin: 10px;
  padding: 10px;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px $brand-light;
  color: $brand-dark;
  .name {
    margin-bottom: 10px;
  }
}
