@import '/src/styles/colours';

.main {
  display: flex;
  flex-direction: column;
  margin-top: 60px;

  .closeBtn {
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 50px;
    height: 50px;
    background-color: $brand-dark;
    border-radius: 50%;
  }

  .left {
    width: 100%;
    background-color: $brand-dark;

    & > div {
      width: 60%;
      color: white;
    }
  }

  .payment {
    display: flex;
    justify-content: space-between;
  }

  .right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 10px;
    justify-content: center;
  }
}

.img {
  display: flex;
  flex: 1;
  height: 250px;
  img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
  }
}

.cardIcon {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
