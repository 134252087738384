@font-face {
  font-family: 'ProductSans';
  src: url('../assets/fonts/ProductSansRegular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'ProductSans';
  src: url('../assets/fonts/ProductSansBold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'ProductSansItalic';
  src: url('../assets/fonts/ProductSansItalic.ttf');
  font-weight: 400;
}
