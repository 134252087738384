.box {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(250, 250, 255);
  color: black;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.54);
  width: 500px;
  height: 500px;
  margin: 2rem;
  padding: 2rem;
  border-radius: 1rem;
}

.box:hover {
  /*background-color: rgb(0, 0, 25, 0.04);*/
  background-color: rgba(0, 0, 100, 0.08);
  box-shadow: 3px 3px 16px rgba(0, 0, 0, 0.6);
}

.qr-code-button {
  padding: 0.5em 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.3em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .box {
    width: 40%;
  }
}

.optionsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  width: 100%;
}

.box button {
  padding: 0.5em 1em;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.3em;
  width: 200px;
  height: 50px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.box button:hover {
  background-color: #0056b3;
}

.box button:disabled {
  background-color: gray;
}
