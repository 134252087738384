@import '/src/styles/colours';
@import '/src/styles/mixins';

.scrollableList {
  // overflow-y: scroll;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  height: 100%;
  scroll-snap-type: y mandatory;
  margin-top: 15px;
}

@include sm-max {
  .scrollableList {
    display: none;
  }
}

// // ::-webkit-scrollbar {
// //   width: 0;
// //   height: 0;
// //   position: absolute;
// // }

// // ::-webkit-scrollbar-track {
// //   border-radius: 0;
// // }

// // ::-webkit-scrollbar-thumb {
// //   background: unset;
// //   border-radius: 0;
// // }

// // /* Handle on hover */
// // // ::-webkit-scrollbar-thumb:hover {
// // //   background: unset;
// // // }

// //   -webkit-overflow-scrolling: touch;
// //   scroll-snap-type: x mandatory;
// //   scroll-behavior: smooth;
// //   z-index: 200;
// // }

.wrapperScroll {
  list-style: none;
  padding: 0;
  margin: 0;

  //   // --item-box-height: 100px;
  //   // padding-inline: 1rem;
  //   // background-color: unset;
  //   // user-select: none;
  //   // width: 100vw;
  //   // height: calc(var(--item-box-height) * 1.2);
  //   // overflow: scroll;
  //   // display: flex;
  //   // gap: 1rem;
  //   // flex-wrap: nowrap;
  //   // align-items: center;
  //   // transform: translateX(0px);
}

// // .item {
// //   width: 300px;
// //   height: 300px;
// //   padding: 10px;
// //   margin-right: 10px;
// //   background-color: red;
// //   transition: transform 0.3 ease-out;
// // }
