@import '/src/styles/colours';
@import '/src/styles/mixins';

.scrollReview {
  overflow-y: scroll;
  overflow-x: hidden;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  height: auto;
  scroll-snap-type: y mandatory;
  margin-top: 3%;
  background-color: white;
}
