$white: #fff;
$lightBlue: #e5f1f9;
$blue: #0364ca;
$warningBackground: #fff1f0;
$warningBorder: #ffccc7;
$textBlack: black;
$backgroundWhite: white;
$hoverGray: #fafafa;
$warningRed: #c7363d;
$successBackground: #f1ffe4;
$successGreen: #499401;
$successLightGreen: #37b323;

$brand-dark: #162b4a;

$primary-light: #659ad1;
$primary-medium: #5c8dc0;
$primary-dark: #4f79a5;

$alert-pail: #fff1f0;
$alert-light: #d7556d;
$alert-light2: #d35169;
$alert-medium: #bd4359;
$alert-dark: #a73348;

$confirm-light: #8cd280;
$confirm-medium: #82c078;
$confirm-dark: #6ea565;

$neutral-pail: #f5f5f5;
$neutral-light: #c8c2c2;
$neutral-medium: #b5aeae;
$neutral-dark: #9b9b9b;

$text-light: #5c5c5c;

$main-blue: #f4f3f3;
$brand-light: #659ad1;
